import asyncComponent from "./AsyncComponent";
const Dashboard = asyncComponent(() => import("../layout/Dashboard"));
const Home = asyncComponent(() => import("../views/Home/Home"));
const About = asyncComponent(() =>
  import("../views/About/CompanyProfile/About")
);
const Bod = asyncComponent(() => import("../views/About/BOD/Bod"));
const Mgmt = asyncComponent(() => import("../views/About/Management/Mgmt"));
const Project1 = asyncComponent(() => import("../views/Projects/Project01"));
const Project2 = asyncComponent(() => import("../views/Projects/Project02"));
const SalientFeature = asyncComponent(() =>
  import("../views/Projects/SalientFeature")
);
const FurtherDetailPage = asyncComponent(() =>
  import("../views/Projects/FurtherDetailPage")
);
const PackageOne = asyncComponent(() =>
  import("../views/Projects/Package-one")
);
const PackageTwo = asyncComponent(() =>
  import("../views/Projects/Package-two")
);
const PackageThree = asyncComponent(() =>
  import("../views/Projects/Package-three")
);
const ProjectSupervision = asyncComponent(() =>
  import("../views/Projects/ProjectSupervision")
);
const POE = asyncComponent(() => import("../views/Projects/Poe"));
const Esmsp = asyncComponent(() => import("../views/Projects/esmsp"));
const Notices = asyncComponent(() => import("../views/Media/Notices"));
const RulesRegulation = asyncComponent(() =>
  import("../views/RulesRegulation")
);
const Faq = asyncComponent(() => import("../views/FAQ/Faq"));
const Contact = asyncComponent(() => import("../views/Contact/Contact"));
const Search = asyncComponent(() => import("../views/Search/Search"));
const Report = asyncComponent(() => import("../views/Report/Report"));
const Event = asyncComponent(() => import("../views/Media/Event"));
const ProjectLocation = asyncComponent(() =>
  import("../views/Projects/ProjectLocation")
);
const Document = asyncComponent(() => import("../views/Media/Document"));
const Gallery = asyncComponent(() => import("../views/Media/Gallery"));
const CareerList = asyncComponent(() => import("../views/Career/CareerList"));
const CareerDetail = asyncComponent(() =>
  import("../views/Career/CareerDetail")
);
const EventDetail = asyncComponent(() => import("../views/Media/EventDetail"));
const DevisionHead = asyncComponent(() =>
  import("../views/About/divisionHead")
);
const NewsLetterEnglish = asyncComponent(() =>
  import("../views/Newsletter/NewsLetterEnglish")
);
const NewsLetterNepali = asyncComponent(() =>
  import("../views/Newsletter/NewsLetterNepali")
);
// private route
const Login = asyncComponent(() => import("core/Public/Login/AdminLogin"));
const AdminDashboard = asyncComponent(() =>
  import("core/Protected/Dashboard/App")
);
const DashboardDocument = asyncComponent(() =>
  import("core/Protected/Dashboard/App")
);
const DashboardMonthlyReport = asyncComponent(() =>
  import("core/Protected/Dashboard/layout/report/monthlyReport")
);
const SafeGaurdDocument = asyncComponent(() =>
  import("../views/Safegaurd/SafeGaurdDocument")
);
const SafeGaurdDocumentBytype = asyncComponent(() =>
  import("../views/Safegaurd/SafeGaurdDocumentBytype")
);
const SafeGaurdActivity = asyncComponent(() =>
  import("../views/Safegaurd/SafegurdActivity")
);
const SafegurdDetail = asyncComponent(() =>
  import("../views/Safegaurd/SafegurdDetail")
);

const appRoutes = [
  {
    path: "/login",
    component: Login,
    type: "unauthorized",
  },

  {
    path: "/",
    component: Dashboard,
    type: "unauthorized",
    children: [
      {
        path: "/",
        component: Home,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/about",
        component: About,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/bod",
        component: Bod,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/division-head",
        component: DevisionHead,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/mgmt",
        component: Mgmt,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/project1",
        component: Project1,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/project2",
        component: Project2,
        exact: true,
      },
      {
        path: "/salient-feature",
        component: SalientFeature,
        exact: true,
      },
      {
        path: "/salient-feature-uthp",
        component: FurtherDetailPage,
        exact: true,
      },
      {
        path: "/package-one-uthp",
        component: PackageOne,
        exact: true,
      },
      {
        path: "/package-two-uthp",
        component: PackageTwo,
        exact: true,
      },
      {
        path: "/package-three-uthp",
        component: PackageThree,
        exact: true,
      },
      {
        path: "/package-project-supervision-uthp",
        component: ProjectSupervision,
        exact: true,
      },
      {
        path: "/package-esmsp-uthp",
        component: Esmsp,
        exact: true,
      },
      {
        path: "/package-poe-uthp",
        component: POE,
        exact: true,
      },
      {
        path: "/notice",
        component: Notices,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/rules-regulation",
        component: RulesRegulation,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/news-letter-english",
        component: NewsLetterEnglish,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/news-letter-nepali",
        component: NewsLetterNepali,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/faq",
        component: Faq,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/contact",
        component: Contact,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/report/:type",
        component: Report,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/event",
        component: Event,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/project-location",
        component: ProjectLocation,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/doc",
        component: Document,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/gallery",
        component: Gallery,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/career",
        component: CareerList,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/career/:id",
        component: CareerDetail,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/gallery",
        component: Gallery,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/event/:id",
        component: EventDetail,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/safe-gurd-document",
        component: SafeGaurdDocument,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/safe-gurd-document/:type",
        component: SafeGaurdDocumentBytype,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/safe-gurd-activity",
        component: SafeGaurdActivity,
        exact: true,
        type: "unauthorized",
      },
      {
        path: "/safe-gurd-activity/:id",
        component: SafegurdDetail,
        exact: true,
        type: "unauthorized",
      },
    ],
  },
  {
    path: "/dashboard",
    component: AdminDashboard,
    type: "authorized",
    children: [
      {
        path: "/dashboard/document",
        component: DashboardDocument,
        type: "authorized",
        exact: true,
      },
      {
        path: "/dashboard/monthly-report",
        component: DashboardMonthlyReport,
        type: "authorized",
        exact: true,
      },
    ],
  },
];

export default appRoutes;
