import { combineReducers } from "redux";
import deleteReportReducer from "./deleteReport";
import getReportReducer from "./getReport";
import saveReportReducer from "./saveMonthlyReport";

export default combineReducers({
  saveDocument: saveReportReducer,
  getReport: getReportReducer,
  deleteDocument: deleteReportReducer,
});
