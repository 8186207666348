import externalApiList from "./External";
import internalApiList from "./Internal";

export enum RequestMethod {
  GET = "GET",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  PURGE = "PURGE",
  LINK = "LINK",
  UNLINK = "UNLINK",
}

export enum RequestBodyType {
  /**If request id in application/x-www-form-urlencoded as string*/
  QUERYSTRING = "QUERY-STRING",
  /**If request is in formdata*/
  FORMDATA = "FORM-DATA",
  /**If request requires Bearer*/
  AUTH = "AUTH",
  /**If request is open*/
  NOAUTH = "NO-AUTH",
  /** If response type is file */
  FILE = "FILE",
}

/**
 * API detail with redux action associated with it
 */
export interface ApiDetailType {
  /**Redux Action Name */
  actionName: string;
  /**Request API URI */
  controllerName: string;
  /**Request Method; Defaults as GET */
  requestMethod?: RequestMethod;
  /**Request Body Type */
  requestBodyType?: RequestBodyType;
}

const apiDetails = {
  local: {
    i18n: {
      controllerName: "",
      actionName: "I18N",
    },
    changePasswordModal: {
      controllerName: "",
      actionName: "CHANGE_PASSWORD_MODAL",
      requestMethod: RequestMethod.POST,
    },
    myActiveTab: {
      controllerName: "",
      actionName: "MY_PROFILE_TAB_MODAL",
    },
  },
  oauth: {
    login: {
      controllerName: "api/v1/users/login",
      actionName: "LOGIN",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.AUTH,
    },
    init: {
      controllerName: "/oauth/privileges/get/init",
      actionName: "GET_PRIVILEGES",
      requestMethod: RequestMethod.GET,
    },
  },
  document: {
    saveDocument: {
      controllerName: "api/v1/document",
      actionName: "SAVE_DOWNLOAD",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.AUTH,
    },
    getDocument: {
      controllerName: "api/v1/document",
      actionName: "GET_DOWNLOAD",
      requestMethod: RequestMethod.GET,
    },
    deleteDocument: {
      controllerName: "api/v1/document/{id}",
      actionName: "DELETE_DOCUMENT",
      requestMethod: RequestMethod.DELETE,
    },
  },
  career: {
    saveCareer: {
      controllerName: "api/v1/career",
      actionName: "SAVE_CAREER",
      requestMethod: RequestMethod.POST,
      // requestBodyType: RequestBodyType.,
    },
    getCareer: {
      controllerName: "api/v1/Career",
      actionName: "GET_CAREER",
      requestMethod: RequestMethod.GET,
    },
    getOneCareer: {
      controllerName: "api/v1/Career/{id}",
      actionName: "GET_CAREER",
      requestMethod: RequestMethod.GET,
    },
    deleteCareer: {
      controllerName: "api/v1/Career/{id}",
      actionName: "DELETE_CAREER",
      requestMethod: RequestMethod.DELETE,
    },
    saveAppliedCareer: {
      controllerName: "api/v1/applied-for-career",
      actionName: "applied_for_career",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    getAppliedCareer: {
      controllerName: "api/v1/applied-for-career",
      actionName: "GET_Applied_CAREER",
      requestMethod: RequestMethod.GET,
    },
  },
  safegaurd: {
    saveDocument: {
      controllerName: "api/v1/safegaurd-document",
      actionName: "SAVE_SAFE_GAURD_DOWNLOAD",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.AUTH,
    },
    getDocument: {
      controllerName: "api/v1/safegaurd-document",
      actionName: "GET_safegaurd-document_DOWNLOAD",
      requestMethod: RequestMethod.GET,
    },
    deleteDocument: {
      controllerName: "api/v1/safegaurd-document/{id}",
      actionName: "DELETE_safegaurd-document_DOCUMENT",
      requestMethod: RequestMethod.DELETE,
    },
    saveActivity: {
      controllerName: "api/v1/safegaurd-activity",
      actionName: "SAVE_SAFE_GAURD_ACTIVITY",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    getActivity: {
      controllerName: "api/v1/safegaurd-activity",
      actionName: "GET_safegaurd-activity_DOWNLOAD",
      requestMethod: RequestMethod.GET,
    },
    getsingleActivity: {
      controllerName: "api/v1/safegaurd-activity/{id}",
      actionName: "GET_safegaurd-SINGLE-DOCUMENT",
      requestMethod: RequestMethod.GET,
    },
    deleteActivity: {
      controllerName: "api/v1/safegaurd-activity/{id}",
      actionName: "DELETE_safegaurd-activity_DOCUMENT",
      requestMethod: RequestMethod.DELETE,
    },
  },
  rulesAndRegulation: {
    save: {
      controllerName: "api/v1/rulesAndRegulation",
      actionName: "SAVE_RULESANDREGULATION",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    get: {
      controllerName: "api/v1/rulesAndRegulation",
      actionName: "GET_RULESANDREGULATION",
      requestMethod: RequestMethod.GET,
    },
    delete: {
      controllerName: "api/v1/rulesAndRegulation/{id}",
      actionName: "DELETE_RULESANDREGULATION",
      requestMethod: RequestMethod.DELETE,
    },
  },
  report: {
    saveReport: {
      controllerName: "api/v1/report",
      actionName: "SAVE_REPORT",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.AUTH,
    },
    getReport: {
      controllerName: "api/v1/report",
      actionName: "GET_REPORT",
      requestMethod: RequestMethod.GET,
    },
    deleteReport: {
      controllerName: "api/v1/report/{id}",
      actionName: "DELETE_REPORT",
      requestMethod: RequestMethod.DELETE,
    },
  },
  faq: {
    saveFAQ: {
      controllerName: "api/v1/faq",
      actionName: "SAVE_FAQ",
      requestMethod: RequestMethod.POST,
      // requestBodyType: RequestBodyType.FORMDATA,
    },
    getFAQ: {
      controllerName: "api/v1/faq",
      actionName: "GET_FAQ",
      requestMethod: RequestMethod.GET,
    },
    deleteFAQ: {
      controllerName: "api/v1/faq/{id}",
      actionName: "DELETE_FAQ",
      requestMethod: RequestMethod.DELETE,
    },
  },
  contact: {
    saveContact: {
      controllerName: "api/v1/contact",
      actionName: "SAVE_CONTACT",
      requestMethod: RequestMethod.POST,
      // requestBodyType: RequestBodyType.FORMDATA,
    },
    getContact: {
      controllerName: "api/v1/contact",
      actionName: "GET_CONTACT",
      requestMethod: RequestMethod.GET,
    },
  },
  media: {
    saveNews: {
      controllerName: "api/v1/news",
      actionName: "SAVE_NEWS",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    getNews: {
      controllerName: "api/v1/news",
      actionName: "GET_NEWS",
      requestMethod: RequestMethod.GET,
    },
    getSingleNews: {
      controllerName: "api/v1/news/{id}",
      actionName: "GET_NEWS",
      requestMethod: RequestMethod.GET,
    },
    deleteNews: {
      controllerName: "api/v1/news/{id}",
      actionName: "DELETE_NEWS",
      requestMethod: RequestMethod.DELETE,
    },
    saveMedia: {
      controllerName: "api/v1/media",
      actionName: "SAVE_MEDIA",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    getMedia: {
      controllerName: "api/v1/media",
      actionName: "GET_MEDIA",
      requestMethod: RequestMethod.GET,
    },
    deleteMedia: {
      controllerName: "api/v1/media/{id}",
      actionName: "DELETE_MEDIA",
      requestMethod: RequestMethod.DELETE,
    },
    saveNewsLatter: {
      controllerName: "api/v1/newsLatter",
      actionName: "SAVE_NewsLatter",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.AUTH,
    },
    getNewsLatter: {
      controllerName: "api/v1/newsLatter",
      actionName: "GET_NewsLatter",
      requestMethod: RequestMethod.GET,
    },
    deleteNewsLatter: {
      controllerName: "api/v1/newsLatter/{id}",
      actionName: "DELETE_NEWSLATTER",
      requestMethod: RequestMethod.DELETE,
    },
    saveNotice: {
      controllerName: "api/v1/notice",
      actionName: "SAVE_NOTICE",
      requestMethod: RequestMethod.POST,
      // requestBodyType: RequestBodyType.,
    },
    updateNotice: {
      controllerName: "api/v1/notice/{id}",
      actionName: "SAVE_NOTICE",
      requestMethod: RequestMethod.PUT,
      // requestBodyType: RequestBodyType.,
    },
    getNotice: {
      controllerName: "api/v1/notice",
      actionName: "GET_NOTICE",
      requestMethod: RequestMethod.GET,
    },
    deleteNotice: {
      controllerName: "api/v1/notice/{id}",
      actionName: "DELETE_NOTICE",
      requestMethod: RequestMethod.DELETE,
    },
  },
  slider: {
    deleteSlider: {
      controllerName: "api/v1/slider/{id}",
      actionName: "DELETE_SLIDER",
      requestMethod: RequestMethod.DELETE,
    },
    saveSlider: {
      controllerName: "api/v1/slider",
      actionName: "SAVE_SLIDER",
      requestMethod: RequestMethod.POST,
      requestBodyType: RequestBodyType.FORMDATA,
    },
    getSlider: {
      controllerName: "api/v1/slider",
      actionName: "GET_SLIDER",
      requestMethod: RequestMethod.GET,
    },
  },
};

type ApiList = typeof apiDetails;
export const apiList: ApiList = apiDetails;
