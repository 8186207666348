import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: {
      hello: "Hello",
      greet: "Hello, {{name}}!",
      documentation: "Go To Documentation",

      viewOurProject: " View all our Projects",

      pages: {
        home: "Home",
        about: "About",
        companyProfile: "Company Profile",
        bod: "Board of Directors",
        divisionHead: "Division Head",
        managementTeam: "Management Team",
        project: "Project",
        projectUpper: "Tanahu Hydropower Project (Upper Seti HEP)",
        projectLower: "Lower Seti (Tanahu) Hydropower Project",
        projectLocation: "Project Location",
        reports: "Reports",
        annualReports: "Annual Reports",
        qpr: " Quarterly Progress Report",
        qrSocial: "Social Safeguard Monitoring Report",
        qrEnvironmaltal: `Environmental Safeguard Monitoring
                          Report`,
        panelOfExportReport: "Panel of Experts Report",
        essmr: "External Social Monitoring Report (ESSMR)",
        eesmr: "External Environment  Monitoring Report (EESMR)",
        monthlyReport: "Monthly Report",
        mediaCenter: "Media Center",
        eventsAndUpdate: "Events & Updates",
        gallery: "Gallery",
        imagegallery: "Image Gallery",
        videogallery: "Video Gallery",
        document: "Documents",
        notices: "Notices",
        newletterEn: "Newsletter (English)",
        newsletterNp: "Newsletter (Nepali)",
        Safegurd: "Safeguard",
        safeGurdDoc: "Major Document",
        safeGaurdActivity: "Safeguard Activity",
        ruleAndRegulation: "Rules and Regulations",
      },
      home: {
        aboutUs: "About Us",
        kks: "Kiran Kumar Shrestha",
        md: "Managing Director",
        rbs: "Raja Bhai Shilpakar",
        apm: " Project Manager",
        sg: "Sachen Gautam",
        sc: "Communication Specialist",
        kkp: "Krishna Kanta Pandit",
        cs: "Company Secretary",

        aboutText1:
          "Tanahu Hydropower Limited (THL) was established as a subsidiary company of Nepal Electricity Authority (NEA) on 25 March 2012 to develop Tanahu Hydropower Project (previously known as Upper Seti Hydropower Project). The Project site is situated 150 km west of Kathmandu on Seti River near Damauli of Tanahu District in Gandaki Provionce. The overall management of THL is entrusted to the Board of Directors, headed by the Managing Director of NEA.",
        aboutText2:
          "The Project is storage type hydropower project with installed capacity of 140 MW and an estimated average annual energy generation of 587.7 GWh (Years 1-10) and 489.9 GWh (Year 11 onwards). The total project cost is estimated at $505 million including Rural Electrification, taxes, duties, interest charges during implementation and contingencies.",
        aboutText3:
          "The company also envisages developing Lower Seti (Tanahu) Hydropower Project at the lower reaches of Seti River, i.e. downstream of the Tanahu Hydropower Project (THP). It is envisaged as the integrated system of THP which will be utilizing its releases with about six hours of peaking capability during dry season.",

        project: {
          title: "Our Projects",
          text: `Tanahu Hydropower Limited (THL) is continuously updating the project's updates to the public.`,
          viewAllProject: "View  Projects",
        },
        recentActivity: "Recent Activities",
        latestNotice: "Latest Notice",
      },
      footer: {
        text: `Tanahu Hydropower Limited (THL) was established as a subsidiary
                company of Nepal Electricity Authority (NEA) on 25 March 2012 to
                develop Tanahu Hydropower Project`,
        company: "Company",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        career: "Careers",
        ourPoiningPosition: "Our Open Positions",
        faq: "FAQ's",
        project: "Projects",
        newsAndEvent: "News & Events",
        webmail: "Webmail",

        usefullLink: "Useful Links",
        moe: "Ministry of Energy, Water Resources and Irrigation",
        nea: "Nepal Electricity Authority",
        eib: "European Investment Bank",
        jicb: "Japan International Cooperation Agency ",
        adb: "Asian Development Bank",

        connectWithUs: "Connect with us",
        location: "Chundevi Marga, House No:217, Maharajgunj, Kathmandu, Nepal",
      },
      bod: {
        title: "Board of Directors",
        kg: "Kul Man Ghising",
        chairmain: "Chairman",
        rb: "Ramji Bhandari",
        bd: "Board Director",
        pkt: "Pradip Kumar Thike",
        ll: "Lokhari Luitel",
      },
      divisionHead: {
        rbs: "Raja Bhai Shilpakar",
        apm: " Project Manager",
        sb: "Shyamji Bhandari",
        manager: "Acting Project Manager",
        dks: "Dinesh Kumar Shrestha",
        fdc: "Finance/Admin Division Chief",
        kkp: "Krishna Kanta Pandit",
        cs: "Company Secretary",
        gdb: "Ganesh Datta Bhatta",
        kh: "Khimananda Chaudhary",
        fdc1: "Finance Division Chief",
        adccs: "Admin Division Chief/ Company Secretary",
        npa: "Narayan Prasad Acharya",
        hks: "Hari Kumar Shrestha",
        esmu: "ESMU Division Chief ",
      },
      managementTeam: {
        bd: "Bidur Adhikari",
        dm: "DM (Infrastructure & Road-PSC)",
        rs: "Rajesh Sapkota",
        deputyManagerPSC: "Deputy Manager (Contract- PSC)",
        ga: "Gunjan Atreya",
        deputyManagerPSCEL: "Deputy Manager (Electrical)",
        mrp: "Madan Raj Pokharel",
        as: "Assistant Manager",
        dk: "Dipa Khakurel",
        kc: "Kiran Chaulagai",
        bp: "Bhojraj Pokharel",
        ad: "Assistant Director (Admin)",
        kpp: "Krishna Prasad Pokharel",
        am: "Kabita Sharma",
      },
      about: {
        wwa: "Who We Are",
      },
      eventAndUpdate: {
        title: "Recent Activities & Updates",
      },
      newsLetter: {
        title: "Newsletter",
      },
      safeGuard: {
        sd: "Safeguard Document",
        cemp: "Consolidated Environment Management Plan",
        fish: "Fish Conservation Management Plan ",
        forest: "Forest and Watershed Management Plan",
        wildLife: "Wildlife Conservation Awareness Plan",
        riverSafety: "River Safety Management Plan",

        otherDocument: "Major Documents",
        rippOfHp: "RIPP for Hydropower Component",
        rippOfTL: "RIPP for Transmission Line Component",
        eia: "Environment Impact Assessment (EIA), 2009",
        ea: "Environment Addendum, 2012",
        seia: "Supplementry Environment Impact Assessment (SEIA), 2017",
        iee: "Initial Environment Examination (IEE)",
        siee: "Supplementry Initial Environment Examination",
        riee: "Revised Initial Environment Examination",

        activity: "Safeguard Activities & Updates",
      },
      career: {
        applyNow: "Apply Now",
        fn: "First Name",
        lastname: "Last Name",
        phoneNo: "Phone No.",
        email: "Email Address",
        cv: "Upload your CV here",
        coverletter: "Upload your Cover Letter here",
        submit: "Submit",

        cod: "Office Details",
        address: "Address",
        contactNo: "Contact No.",
      },
      contactUs: {
        message: "Send a Message",
        yourMessage: "Your Message ...",
        send: "Send",
        informationCenter: "Information Centers",
        tanahuSideOffice: "Tanahu Hydropower Limited Site Office",
        fullName: "Full Name",
      },
      viewAll: "View All",
      noData: "There are no data available.",
      getInTouch: "GET IN TOUCH",
      latestNotice: "Latest Notices",
      ourProject: "Our Projects",
      ld: "Latest Documents",
      required: "This is requred field!",
    },
  },
  ne: {
    translation: {
      hello: "नमस्कार",
      greet: "नमस्कार, {{name}}!",
      documentation: "दस्तावेजीकरणमा जानुहोस",

      viewOurProject: " हाम्रा सबै परियोजनाहरू हेर्नुहोस्",
      pages: {
        home: "होम",
        about: "हाम्रो बारेमा",
        companyProfile: "कम्पनी प्रोफइल",
        bod: "सञ्चालक समिति",
        divisionHead: "डिभिजन प्रमुख",
        managementTeam: "व्यवस्थापन टोली",
        project: "परियोजना",
        projectUpper: "तनहुँ जलविद्युत आयोजना (Upper Seti HEP)",
        projectLower: "तनहुँ जलविद्युत आयोजना (Lower Seti HEP)",
        projectLocation: "परियोजना स्थान",
        reports: "रिपोर्टहरू",
        annualReports: "वार्षिक रिपोर्टहरू",
        qpr: "त्रैमासिक प्रगति रिपोर्ट",
        qrSocial: "Social Safeguard Monitoring Report",
        qrEnvironmaltal: ` Environmental Safeguard Monitoring Report`,
        panelOfExportReport: "Panel of Experts Report",
        essmr: "External Monitoring Report (ESSMR)",
        eesmr: "External Monitoring Report (EESMR)",
        monthlyReport: "मासिक रिपोर्ट",
        mediaCenter: "मिडिया सेन्टर",
        eventsAndUpdate: "घटनाक्रम र अद्यावधिकहरू",
        gallery: "ग्यालेरी",
        imagegallery: "इमेज ग्यालेरी",
        videogallery: "भिडियो ग्यालरी",
        document: "कागजातहरू",
        notices: "सूचनाहरू",
        newletterEn: "समाचार पत्र (अंग्रेजी)",
        newsletterNp: "समाचार पत्र (नेपाली)",
        Safegurd: "सेफगार्ड",
        safeGurdDoc: "सेफगार्ड कागजातहरू",
        safeGaurdActivity: "सेफगार्ड गतिविधि",
        ruleAndRegulation: "नियम र नियमहरू",
      },
      home: {
        aboutUs: "हाम्रो बारेमा",
        kks: "किरण कुमार श्रेष्ठ",
        md: "प्रबन्ध निर्देशक",
        rbs: "राजा भाई शिल्पकार",
        apm: "कार्यवाहक परियोजना प्रबन्धक",
        sg: "सचेन गौतम",
        sc: "सञ्चार विशेषज्ञ",
        kkp: "कृष्णकान्त पण्डित",
        cs: "कम्पनी सचिव",

        aboutText1:
          "Tanahu Hydropower Limited (THL) was established as a subsidiary company of Nepal Electricity Authority (NEA) on 25 March 2012 to develop Tanahu Hydropower Project (previously known as Upper Seti Hydropower Project). The Project site is situated 150 km west of Kathmandu on Seti River near Damauli of Tanahu District in Gandaki Provionce. The overall management of THL is entrusted to the Board of Directors, headed by the Managing Director of NEA.",
        aboutText2:
          "The Project is storage type hydropower project with installed capacity of 140 MW and an estimated average annual energy generation of 587.7 GWh (Years 1-10) and 489.9 GWh (Year 11 onwards). The total project cost is estimated at $505 million including Rural Electrification, taxes, duties, interest charges during implementation and contingencies.",
        aboutText3:
          "The company also envisages developing Lower Seti (Tanahu) Hydropower Project at the lower reaches of Seti River, i.e. downstream of the Tanahu Hydropower Project (THP). It is envisaged as the integrated system of THP which will be utilizing its releases with about six hours of peaking capability during dry season.",

        project: {
          title: "जलविद्युत उद्योगमा परियोजनाहरू",
          text: `तनहुँ हाइड्रोपावर लिमिटेड (THL) ले आयोजनाको अपडेट जनतालाई निरन्तरता दिइरहेको छ।`,
          viewAllProject: "सबै परियोजनाहरू हेर्नुहोस्",
        },
        recentActivity: "हालका गतिविधिहरू",
        latestNotice: "हालको सूचना",
      },
      footer: {
        text: `तनहुँ हाइड्रोपावर लिमिटेड (THL) सहायक कम्पनीको रूपमा स्थापना भएको थियो|
                नेपाल विद्युत प्राधिकरण (NEA) को कम्पनीले २०६८ चैत १२ देखि
                तनहुँ जलविद्युत आयोजना निर्माण भएको थियो|`,
        company: "कम्पनी",
        aboutUs: "हाम्रोबारे",
        contactUs: "सम्पर्क विवरण",
        career: "करियरहरू",
        ourPoiningPosition: "हाम्रो कम्पनीमा खुलेका पदहरू",
        faq: "FAQ's",
        project: "परियोजनाहरू",
        newsAndEvent: "समाचार र घटनाक्रम",
        webmail: "वेबमेल",

        usefullLink: "उपयोगी लिङ्कहरू",
        moe: "ऊर्जा मन्त्रालय",
        nea: "नेपाल विद्युत प्राधिकरण",
        eib: "European Investment Bank",
        jica: "Japan International Cooperation Agency",
        adb: "Asian Development Bank",

        connectWithUs: "हामीलाई सम्पर्क गर्नुहोस",
        location: "चुनदेवी मार्ग, घर नं: २१७, महाराजगञ्ज, काठमाडौं, नेपाल",
      },
      bod: {
        title: "सञ्चालक समिति",
        kg: "कुलमान घिसिङ",
        chairmain: "अध्यक्ष",
        rb: "रामजी भण्डारी",
        bd: "बोर्ड निर्देशक",
        pkt: "प्रदीप कुमार थिके",
        ll: "लोकहरी लुईटेल",
      },
      divisionHead: {
        rbs: "राजा भाइ सिल्पकार",
        apm: "कार्यवाहक परियोजना प्रबन्धक",
        sb: "श्यामजी भण्डारी",
        manager: "परियोजना प्रबन्धक",
        dks: "दिनेशकुमार श्रेष्ठ",
        fdc: "अर्थ महाशाखा प्रमुख",
        fdc1: "अर्थ महाशाखा प्रमुख",
        kkp: "कृष्ण कान्त पण्डित",
        cs: "कम्पनी सचिव",
        gdb: "गणेश दत्त भट्ट",
        adccs: "प्रशासन विभाग प्रमुख / कम्पनी सचिव",
        npa: "नारायण प्रसाद आचार्य",
        kh: "खिमनन्द चौधरी",
        hks: "हरी कुमार श्रेष्ठ ",
        esmu: "ESMU Division Chief ",
      },
      managementTeam: {
        bd: "बिदुर अधिकारी",
        dm: "DM (पूर्वाधार र सडक-PSC)",
        rs: "राजेश सापकोटा",
        deputyManagerPSC: "उप प्रबन्धक (अनुबंध- PSC)",
        ga: "गुञ्जन अत्रेय",
        deputyManagerPSCEL: "उपप्रबन्धक ",
        mrp: "मदनराज पोखरेल",
        as: "सहायक प्रबन्धक",
        dk: "दिपा खकुरेल",
        kc: "किरण चौलागाई",
        bp: "भोजराज पोखरेल",
        ad: "सहायक निर्देशक (प्रशासन)",
        kpp: "कृष्णप्रसाद पोखरेल",
        am: "कविता शर्मा",
      },
      about: {
        wwa: "Who We Are",
      },
      eventAndUpdate: {
        title: "हालको गतिविधिहरू",
      },
      newsLetter: {
        title: "समाचार पत्र",
      },
      safeGuard: {
        sd: "Safeguard Document",
        cemp: "Consolidated Environment Management Plan",
        fish: "Fish Conservation Management Plan ",
        forest: "Forest and Watershed Management Plan",
        wildLife: "Wildlife Conservation Awareness Plan",
        riverSafety: "River Safety Management Plan",

        otherDocument: "Major Documents",
        rippOfHp: "RIPP for Hydropower Component",
        rippOfTL: "RIPP for Transmission Line Component",
        eia: "Environment Impact Assessment (EIA), 2009",
        ea: "Environment Addendum, 2012",
        seia: "Supplementry Environment Impact Assessment (SEIA), 2017",
        iee: "Initial Environment Examination (IEE)",
        siee: "Supplementry Initial Environment Examination",
        riee: "Revised Initial Environment Examination",

        activity: "Safeguard Activities & Updates",
      },
      career: {
        applyNow: "आवेदन दिनुहोस्",
        fn: "पहिलो नाम",
        lastname: "थर",
        phoneNo: "फोन नं.",
        email: "इ - मेल ठेगाना",
        cv: "यहाँ आफ्नो CV अपलोड गर्नुहोस्",
        coverletter: "यहाँ आफ्नो आवरण पत्र अपलोड गर्नुहोस्",
        submit: "पेश गर्नुहोस्",

        cod: "कार्यालय विवरण",
        address: "ठेगाना",
        contactNo: "संपर्क नम्बर",
      },
      contactUs: {
        message: "सन्देश पठाउनुहोस्",
        yourMessage: "सन्देश ...",
        send: "पठाउनुहोस्",
        informationCenter: "सूचना केन्द्रहरू",
        tanahuSideOffice: "तनहुँ हाइड्रोपावर लिमिटेड साइट अफिस",
        fullName: "Full Name",
      },
      viewAll: "सबै हेर्नुहोस्",
      noData: "यहाँ कुनै डाटा उपलब्ध छैन।",
      getInTouch: "सम्पर्कमा रहनुहोस्",
      latestNotice: "पछिल्लो सूचनाहरू",
      ourProject: "हाम्रा परियोजनाहरू",
      ld: "नयाँ कागजातहरू",
      required: "यो आवश्यक क्षेत्र हो!",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "ne",
    defaultNS: "common",

    keySeparator: ".", // we use keys in form ('messages.welcome')

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
