import { combineReducers } from "redux";
import deleteSliderReducer from "./deleteSlider";
import getSliderReducer from "./getSlider";
import saveSliderReducer from "./saveSlider";

export default combineReducers({
  saveSliderData: saveSliderReducer,
  getSliderData: getSliderReducer,
  deleteSliderData: deleteSliderReducer,
});
