import React from "react";
import { HashRouter, Redirect, useHistory } from "react-router-dom";
import "./assets/scss/main.scss";
import "./assets/icon/style.css";
import appRoutes from "./routes";
import PrivateRoute from "./routes/PrivateRoute";
import { useDispatch } from "react-redux";
import useAuthentication from "services/authentication/AuthenticationService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerUnderMainta from "views/Servermaintainence";
import "react-datepicker/dist/react-datepicker.css";
// import "bootstrap/less/bootstrap.less";
import "./language/i18n";

function App() {
  const history = useHistory();
  const { isAuthenticated } = useAuthentication();
  console.log(
    isAuthenticated(),
    "test",
    appRoutes.filter((route) => route.type !== "unauthorized")
  );
  // if (window.location.origin.includes("thl.com.np")) {
  //   return <ServerUnderMainta />;
  // }

  return (
    <HashRouter>
      <ToastContainer />
      {/* <PrivateRoute appRoutes={appRoutes} /> */}
      {isAuthenticated() ? (
        <PrivateRoute
          appRoutes={appRoutes.filter((route) => route.type !== "unauthorized")}
        />
      ) : (
        <PrivateRoute
          appRoutes={appRoutes.filter((route) => route.type === "unauthorized")}
          redirectPath={[{ from: "*", to: "/login" }]}
        />
      )}
    </HashRouter>
  );
}

export default App;
