import { combineReducers } from "redux";
import deleteDocumentReducer from "./deleteDocument";
import getAppliedCareerReducer from "./getAppliedCareer";
import getDocumentReducer from "./getDocument";
import getOneCareerReducer from "./getSingleCareer";
import saveAppliedCareerReducer from "./saveAppliedForCareer";
import saveDocumentReducer from "./saveDocument";

export default combineReducers({
  saveCareer: saveDocumentReducer,
  getCareer: getDocumentReducer,
  deleteCareer: deleteDocumentReducer,
  getOneCareer: getOneCareerReducer,
  saveAppliedCareer: saveAppliedCareerReducer,
  getAppliedCareer: getAppliedCareerReducer,
});
