import { combineReducers } from "redux";
import deleteFAQReducer from "./deleteFAQ";
import getFAQReducer from "./getFAQ";
import saveFAQReducer from "./saveFAQ";

export default combineReducers({
  saveFAQ: saveFAQReducer,
  getFAQ: getFAQReducer,
  deleteFAQ: deleteFAQReducer,
});
