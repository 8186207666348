import { combineReducers } from "redux";
import deleterRulesAndRegulationReducer from "./deleteDocument";
import getRulesAndRegulationReducer from "./getDocument";
import saveRulesAndRegulationReducer from "./saveDocument";

export default combineReducers({
  saveRulesAndRegulation: saveRulesAndRegulationReducer,
  getRulesAndRegulation: getRulesAndRegulationReducer,
  deleteRulesAndRegulation: deleterRulesAndRegulationReducer,
});
