import { combineReducers } from "redux";
import deleteDocumentReducer from "./deleteDocument";
import getDocumentReducer from "./getDocument";
import saveDocumentReducer from "./saveDocument";

export default combineReducers({
  saveDocument: saveDocumentReducer,
  getDocument: getDocumentReducer,
  deleteDocument: deleteDocumentReducer,
});
