import { Dispatch } from "redux";

import { AppThunk } from "../..";
import { apiList } from "../../actionNames";
// import { UserCredentials } from "../../../core/Public/Login/Login";
import initDefaultAction from "../../helper/default-action";
import initDefaultReducer from "../../helper/default-reducer";
import initialState from "../../helper/default-state";

const initialLoginState = initialState;
const apiDetails = Object.freeze(apiList.media.updateNotice);

export default function updateNoticeReducer(
  state = initialLoginState,
  action: DefaultAction
): DefaultState<{}> {
  const stateCopy = Object.assign({}, state);
  const actionName = apiDetails.actionName;

  return initDefaultReducer(actionName, action, stateCopy);
}

export const updateNoticeAction =
  (requestData: any): AppThunk<Promise<{}>> =>
  async (dispatch: Dispatch) => {
    //
    const loginData = await initDefaultAction(apiDetails, dispatch, {
      requestData,
      disableSuccessToast: true,
      pathVariables: { id: requestData?._id },
    });

    return loginData;
  };
