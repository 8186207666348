import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  Method,
} from "axios";
import { Dispatch } from "redux";

import initDispatchTypes from "./default-action-type";
import initApiRequest from "../../services/api-request/api-request";

import {
  FailToast,
  SuccessToast,
} from "../../components/React/ToastNotifier/ToastNotifier";
// import { requestTimeoutLanguage, noConnectionLanguage } from '../../i18n/i18n';
import { ApiDetailType } from "store/actionNames";
import { toast } from "react-toastify";

/**
 * Request details for XMLHTTP request
 */
export interface APIRequestDetail {
  /**Request data for the API */
  requestData?: any;
  /** REST API Method
   *
   * This will override requestMethod provided by apiDetails
   */
  requestMethod?: Method;
  /**Path variables present in controller
   *
   * Provided pathVariables -> {id: 1, type: 'test'}
   * Converts controller-url/{id}/{type} -> controller-url/1/test
   */
  //@ts-ignore
  pathVariables?: { [key: string]: Primitive };
  /**Request params
   *
   * Provided params -> {id: 1, type: 'test'}
   * Converts controller-url -> controller-url?id=1&type=test
   */
  //@ts-ignore
  params?: { [key: string]: Primitive };
  /**Axios cancel token source */
  cancelSource?: CancelTokenSource;
  /**Disable Success Toast */
  disableSuccessToast?: boolean;
  /**Disable Failure Toast */
  disableFailureToast?: boolean;
}

interface CustomResponse<TData = any> extends AxiosResponse {
  message: string;
  data: { data: TData; status: number; message: string } | null;
  status: number;
  noconnection: boolean;
  config: AxiosRequestConfig;
  isAxiosError: boolean;
}

export type APIResponseDetail<TData = any> = Promise<CustomResponse<TData>>;

let timeoutLanguageCount = 0;
let noServerConnectionLanguageCount = 0;
let noConnectionLanguageCount = 0;
const axiosCancelSource = Axios.CancelToken.source();

/**
 * Manages API call and updates reducer with success or failure
 * @param apiDetails redux action and api config
 * @param dispatch redux dispatch function
 * @param apiRequestDetails request details for XMLHTTP request
 */
export default async function initDefaultAction(
  apiDetails: ApiDetailType,
  dispatch: Dispatch,
  apiRequestDetails: APIRequestDetail = {}
) {
  const {
    requestData,
    requestMethod,
    pathVariables,
    params,
    cancelSource,
    disableSuccessToast = false,
    disableFailureToast,
  } = apiRequestDetails;

  // Init Dispatch Types
  const dispatchTypes = initDispatchTypes(apiDetails.actionName);

  // Progress Dispatch
  dispatch({ type: dispatchTypes.progressDispatch, payload: null });

  // Check for path variables in controllername
  const sanitizedApiDetails = sanitizeController(apiDetails, pathVariables);

  let responseData;
  try {
    responseData = await initApiRequest(
      sanitizedApiDetails,
      requestData,
      requestMethod || sanitizedApiDetails.requestMethod || "GET",
      params,
      cancelSource || axiosCancelSource
    );

    // If Backend Error
    if (responseData.data?.status === 0) {
      throw responseData;
    }

    // Success Dispatch
    dispatch({
      type: dispatchTypes.successDispatch,
      payload: { ...responseData.data },
    });
    if (disableSuccessToast) {
      // No work done
    } else {
      if (![requestMethod, sanitizedApiDetails.requestMethod].includes("GET")) {
        // SuccessToast();
        toast.success(responseData.data?.message);
      }
    }

    responseData = responseData.data;
  } catch (customThrownError) {
    responseData = customThrownError;

    // Failure Dispatch
    dispatch({
      type: dispatchTypes.failureDispatch,
      payload: { ...responseData.data },
    });
    if (disableFailureToast) {
      // No work done
    } else {
      // debugger;
      responseData.data?.message && toast.error(responseData.data?.message);
    }

    // Axios Timeout
    if (responseData.config?.code === "ECONNABORTED") {
      if (!timeoutLanguageCount) {
        timeoutLanguageCount++;
        // FailToast("Server could not be reached");
        toast.error("Server could not be reached");
      }
    }

    // No Connection
    if (responseData.noconnection) {
      // No Server Connection
      if (responseData.message === "Server could not be reached") {
        if (!noServerConnectionLanguageCount) {
          noServerConnectionLanguageCount++;
          // FailToast("Server could not be reached");
          toast.error("Server could not be reached");
        }
      }
      // No Connection
      else if (responseData.config.code !== "ECONNABORTED") {
        if (!noConnectionLanguageCount) {
          noConnectionLanguageCount++;
          // FailToast("Server could not be reached");
          toast.error("Server could not be reached");
        }
      }
    }
  }

  return responseData;
}

function sanitizeController(
  apiDetail: ApiDetailType,
  pathVariables?: { [key: string]: any }
) {
  return pathVariables && Object.keys(pathVariables).length
    ? {
        ...apiDetail,
        controllerName: Object.entries(pathVariables).reduce(
          (acc, [key, value]) =>
            (acc = acc.replace(`{${key}}`, value.toString())),
          apiDetail.controllerName
        ),
      }
    : apiDetail;
}
