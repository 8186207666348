import { combineReducers } from "redux";
import deleteMediaReducer from "./deleteMedia";
import deleteNewsReducer from "./deleteNews";
import deleteNewsLatterReducer from "./deleteNewsLatter";
import deleteNoticeReducer from "./deleteNotice";
import getMediaReducer from "./getMedia";
import getNewsReducer from "./getNews";
import getNewsLatterReducer from "./getNewsLatter";
import getNoticeReducer from "./getNotice";
import getSingleNewsReducer from "./getSingleNews";
import saveMediaReducer from "./saveMedia";
import saveNewsReducer from "./saveNews";
import saveNewsLatterReducer from "./saveNewsLatter";
import saveNoticeReducer from "./saveNotice";
import updateNoticeReducer from "./updateNotice";

export default combineReducers({
  saveNews: saveNewsReducer,
  getNews: getNewsReducer,
  deleteNews: deleteNewsReducer,
  getMeida: getMediaReducer,
  saveMedia: saveMediaReducer,
  deleteMedia: deleteMediaReducer,
  getNotice: getNoticeReducer,
  saveNotice: saveNoticeReducer,
  deleteNotice: deleteNoticeReducer,
  getSingleNews: getSingleNewsReducer,
  getNewslatter: getNewsLatterReducer,
  saveNewsLetter: saveNewsLatterReducer,
  deleteNewsLetter: deleteNewsLatterReducer,
  updateNoticeData: updateNoticeReducer,
});
