import { Dispatch } from "redux";

import { AppThunk } from "../..";
import { apiList } from "../../actionNames";
// import { UserCredentials } from "../../../core/Public/Login/Login";
import initDefaultAction from "../../helper/default-action";
import initDefaultReducer from "../../helper/default-reducer";
import initialState from "../../helper/default-state";

type LoginResponse = {
  /**jwt access token */
  access_token: string;
  token_type: "bearer";
  refresh_token: string;
  expires_in: number;
  scope: "read write";
};

const initialLoginState = initialState;
const apiDetails = Object.freeze(apiList.contact.getContact);

export default function getContactReducer(
  state = initialLoginState,
  action: DefaultAction
): DefaultState<LoginResponse> {
  const stateCopy = Object.assign({}, state);
  const actionName = apiDetails.actionName;

  return initDefaultReducer(actionName, action, stateCopy);
}

export const getContactAction =
  (): AppThunk<Promise<LoginResponse>> => async (dispatch: Dispatch) => {
    // debugger;
    const loginData = await initDefaultAction(apiDetails, dispatch, {
      disableSuccessToast: true,
    });

    return loginData;
  };
