import { combineReducers } from "redux";
import deleteSafegaurdActivityReducer from "./deleteSafeActivity";
import deleteDocumentReducer from "./deleteSafeDocument";
import getsafeGaurdActivityReducer from "./getActivity";
import getDocumentReducer from "./getDocument";
import getsingleActivityReducer from "./getSingleSafegurd";
import savesafeGaurdActivityReducer from "./saveActivity";
import saveDocumentReducer from "./saveDocument";

export default combineReducers({
  saveDocument: saveDocumentReducer,
  getDocument: getDocumentReducer,
  deleteDocument: deleteDocumentReducer,
  saveActivity: savesafeGaurdActivityReducer,
  getSafegaurdActivity: getsafeGaurdActivityReducer,
  deleteSafegaurdActivity: deleteSafegaurdActivityReducer,
  getOneSafeGurdActivity: getsingleActivityReducer,
});
