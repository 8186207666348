import { AnyAction, combineReducers } from "redux";
import TokenService from "services/jwt-token/jwt-token";
import document from "./modules/document";

// import i18nextReducer from "./modules/i18n/i18n";
import loginReducer from "./modules/login/login";
import media from "./modules/media";
import report from "./modules/report";
import faq from "./modules/faq";
import contact from "./modules/contact";
import rulesAndRegulation from "./modules/rulesAndRegulation";
import safeGaurd from "./modules/safeGaurd";
import career from "./modules/career";
import slider from "./modules/slider";

export const appReducer = combineReducers({
  // i18nextData: i18nextReducer,
  loginData: loginReducer,
  document: document,
  report: report,
  media: media,
  faq: faq,
  contact: contact,
  rulesAndRegulation: rulesAndRegulation,
  safeGaurd: safeGaurd,
  career: career,
  slider: slider,
});

export type RootState = ReturnType<typeof appReducer>;
type TState = ReturnType<typeof appReducer> | undefined;

export default function rootReducer(state: TState, action: AnyAction) {
  if (action.type === "USER_LOG_OUT") {
    state = undefined;
    try {
    } catch (err) {
      console.error("Logout Error", err);
    }
  }

  return appReducer(state, action);
}

export const logoutAction = () => {
  try {
    TokenService.clearToken();
  } catch (err) {
    console.error("LogOut Error", err);
  }

  return { type: "USER_LOG_OUT", payload: {} };
};
